import React, { useEffect, useRef, InputHTMLAttributes, useState } from 'react';
import { useField } from '@unform/core';
import { Container } from './styles';
import theme from '../../../../../../styles/ThemeComponents';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  style?: Record<string, any>;
  name: string;
  unique?: boolean;
  preText?: JSX.Element;
  posText?: JSX.Element;
  background_line?: string;
  options: {
    title: string,
    options: {
      id: string;
      value: string;
      label: string;
      sub_title?: string;
    }[]
  }[];
}
const CheckboxInputGroup: React.FC<Props> = ({
  name,
  options,
  preText = '',
  posText = '',
  unique = false,
  style,
  background_line = '',
  ...rest
}) => {
  const selectRef = useRef(null);
  const { fieldName, registerField, defaultValue = [] } = useField(name);

  const [values, setValues] = useState(defaultValue || []);


  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: undefined,

      getValue: (ref: any) => {
        return unique ? values[0] : values;

      },


      setValue: (ref: any) => {
        return unique ? values[0] : values;

      },
    });
  }, [fieldName, registerField, values]);


  return (
    <Container style={style} {...rest}>
      {preText && <label style={{ border: '0', textAlign: 'justify', marginBottom: '10px', color: theme.color }}>{preText}</label>}
      {options.map((item) => (

        <>

          <h2>{item?.title}</h2>
          <div style={{ width: '100%', display: 'flex', gap: '5px', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap', padding: '10px 0px' }}>
            {item?.options?.map((option, index) => {

              return <label style={{ cursor: 'pointer', color: theme.color, width: '145px', display: 'flex', gap: '5px', justifyContent: 'flex-start', alignItems: 'center', background: background_line ? background_line : '#eee', padding: "5px", margin: ' 0px' }} htmlFor={`${name}_${option.id}`} key={`${name}_${option.id}`}>
                <input style={{ color: theme.color, border: '1px solid#333', minWidth: '20px' }}
                  onClick={(e) => {
                    if (unique) {
                      const find = values?.findIndex(i => i === e.currentTarget?.value);
                      if (find >= 0) {
                        setValues([])
                      }
                      else {
                        setValues([e.currentTarget?.value])
                      }

                    }
                    else {
                      const find = values?.findIndex(i => i === e.currentTarget?.value);
                      if (find >= 0) {
                        const newValues: string[] = [];
                        values?.map((a: string, index) => {
                          if (index !== find) {
                            newValues.push(a);
                          }

                          setValues(newValues)
                        })
                      }
                      else {
                        setValues([...values, e.currentTarget?.value])
                      }

                    }
                  }}
                  checked={values.findIndex((dv: string) => dv === option.id) >= 0}

                  value={option.value}
                  type="checkbox"
                  id={`${name}_${option.id}`}
                  {...rest}
                />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', marginLeft: '10px' }}>
                  <strong style={{ color: theme.color, fontSize: '14px' }}>{option.label}</strong>
                  {option.sub_title && <p style={{ color: theme.color, fontSize: '12px' }}>{option.sub_title}</p>}
                </div>
              </label>

            })}
          </div>
        </>

      ))}

    </Container>
  );
};
export default CheckboxInputGroup;
