import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap, FiArrowRight } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaClinicMedical,
} from 'react-icons/fa';
import { ISigla, readSigla, siglas as siglasRef } from './siglas';
import { MdError, MdDateRange, MdChat } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Forms/Input';
import Button from '../../../components/Forms/Button';
import Loader from '../../../components/Loader';
import MaskedInput from '../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../components/ResponseMessage';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../../components/Forms/SelectSimple';
import Whatsapp from '../../../components/WhatsAppCompartilhar';

import api from '../../../services/api';
import { authTitle, defaultProjectHash, recaptchaKey, urlWebsite } from '../../../config';
import { countries } from './countries';
import TextareaInput from '../../../components/Forms/TextareaInput';
import { useToast } from '../../../hooks/Toast';
import CheckboxInput from '../../../components/Forms/CheckboxInput';
import getCep from '../../../services/cep';
import Axios from 'axios';
import states from './states';
import { useLanguage } from '../../../hooks/Language';
import CompleteSaleFields from './CompleteSaleFields';
import testCPF from '../../../utils/testCPF';
import { addYears, isValid } from 'date-fns';
import { useModal } from '../../../hooks/Modal';
import { useConfiguration } from '../../../hooks/Configuration';
import { loadFromLocalJson } from '../../../utils/loadFromJson';
import { useLoading } from '../../../hooks/LoadingHook';
import { useAuth } from '../../../hooks/Auth';
import CheckboxInputGroup from '../../DashboardPages/core/components/Forms/CheckboxInputGroup';
import { date, dateLanguage } from '../../../utils/date';
interface IRecoverFormData {
  [key: string]: any;
}


interface IRead {
  [key: string]: any;
}

interface IOption {
  value: string;
  label: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
  productId: string;
}

interface ICaptcha {
  getValue(): string;
}

declare var window: any;

interface IOptionsCheckboxGroup {
  title: string,
  options: {
    id: string;
    value: string;
    label: string;
    sub_title?: string;
  }[]
}


const getQuantity = () => {
  let x = 1;
  const options: IOption[] = [];
  while (x <= 300) {
    options.push({ label: x?.toString(), value: x.toString() });

    x += 1;
  }

  return options;
}



const FormContainer = ({ context = {} }) => {
  const { signInDocumentNumber } = useAuth();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(true);
  const formRefConfirmationOK = useRef<FormHandles>(null);
  const formRefConfirmation = useRef<FormHandles>(null);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const { configuration } = useConfiguration();
  const { projectHash = configuration?.url, eventHash = configuration?.current_event_id_response?.url, productId } = useParams<IParams>();
  const [activityHours, setActivityHours] = useState<Record<string, any>[]>([]);
  const [emailRecord, setEmailRecord] = useState('');




  const hours: IOptionsCheckboxGroup[] = activityHours?.reduce((prev: IOptionsCheckboxGroup[], item: Record<string, any>): IOptionsCheckboxGroup[] => {

    const day = dateLanguage(item?.date);
    const index = prev.findIndex(i => i?.title === day);

    if (item?.quantity_used < item?.quantity) {

      if (index >= 0) {
        prev[index].options.push({ label: item?.title, value: item?._id?.toString(), id: item?._id?.toString() })
      }
      else {
        prev.push({
          title: day,
          options: [
            { label: item?.title, value: item?._id?.toString(), id: item?._id?.toString() }
          ]
        })
      }

    }

    return prev;


  }, [] as IOptionsCheckboxGroup[]);





  const hours2 = [
    {
      title: '05 de novembro',
      options: [
        { label: '13h às 15h', value: '05/11 - 13h às 15h', id: '05/11 - 13h às 15h' },
        { label: '15h às 17h', value: '05/11 - 15h às 17h', id: '05/11 - 15h às 17h' },
        { label: '17h às 19h', value: '05/11 - 17h às 19h', id: '05/11 - 17h às 19h' },
      ]
    },
    {
      title: '06 de novembro',
      options: [
        { label: '09h às 11h', value: '06/11 - 09h às 11h', id: '06/11 - 09h às 11h' },
        { label: '11h às 13h', value: '06/11 - 11h às 13h', id: '06/11 - 11h às 13h' },
        { label: '13h às 15h', value: '06/11 - 13h às 15h', id: '06/11 - 13h às 15h' },
        { label: '15h às 17h', value: '06/11 - 15h às 17h', id: '06/11 - 15h às 17h' },
        { label: '17h às 19h', value: '06/11 - 17h às 19h', id: '06/11 - 17h às 19h' },
      ]
    }
    ,
    {
      title: '07 de novembro',
      options: [
        { label: '09h às 11h', value: '07/11 - 09h às 11h', id: '07/11 - 09h às 11h' },
        { label: '11h às 13h', value: '07/11 - 11h às 13h', id: '07/11 - 11h às 13h' },
        { label: '13h às 15h', value: '07/11 - 13h às 15h', id: '07/11 - 13h às 15h' },
        { label: '15h às 17h', value: '07/11 - 15h às 17h', id: '07/11 - 15h às 17h' },
        { label: '17h às 19h', value: '07/11 - 17h às 19h', id: '07/11 - 17h às 19h' },
      ]
    },
    {
      title: '08 de novembro',
      options: [
        { label: '09h às 11h', value: '08/11 - 09h às 11h', id: '08/11 - 09h às 11h' },
        { label: '11h às 13h', value: '08/11 - 11h às 13h', id: '08/11 - 11h às 13h' },
        { label: '13h às 15h', value: '08/11 - 13h às 15h', id: '08/11 - 13h às 15h' },
        { label: '15h às 17h', value: '08/11 - 15h às 17h', id: '08/11 - 15h às 17h' },
        { label: '17h às 19h', value: '08/11 - 17h às 19h', id: '08/11 - 17h às 19h' },
      ]
    }
  ]

  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };
  const siglas = readSigla();
  const { addLoading, removeLoading } = useLoading();
  const { addToast } = useToast();
  const { translate } = useLanguage();
  const { addModal } = useModal();
  const [countryISO, setCountryISO] = useState('BR');
  const [position, setPosition] = useState('');
  const [otherCompany, setOtherCompany] = useState('');
  const [product, setProduct] = useState<Record<string, any>>({});
  const [cities, setCities] = useState<Array<IOption>>([]);
  const [stateValue, setStateValue] = useState('');

  const [escolaCategory, setEscolaCategory] = useState('');
  const [escolaOption, setEscolaOption] = useState<IOption[]>([]);


  const [currentEmail, setCurrentEmail] = useState('');
  const [ready, setReady] = useState(false);
  const [statusConfirmation, setStatusConfirmation] = useState('new-subscriber');



  const loadEscolaOptions = async (value) => {

    if (value === 'Pública') {
      addLoading({ title: 'Verificando', key: 'loading' })
      const newOptions = await loadFromLocalJson({ url: '/apoio/publicas.json' });
      setEscolaOption(newOptions)

      removeLoading('loading')

    }
    else if (value === 'Privada') {
      addLoading({ title: 'Verificando', key: 'loading' })
      const newOptions = await loadFromLocalJson({ url: '/apoio/privado.json' });
      setEscolaOption(newOptions)

      removeLoading('loading')
    }
    else if (value === 'Rede SESI-RS') {

      setEscolaOption([
        { label: "CT Bento Gonçalves", value: "CT Bento Gonçalves" },
        { label: "CT Campo Bom", value: "CT Campo Bom" },
        { label: "CT Erechim", value: "CT Erechim" },
        { label: "CT Esteio", value: "CT Esteio" },
        { label: "CT Estrela", value: "CT Estrela" },
        { label: "CT Farroupilha", value: "CT Farroupilha" },
        { label: "CT Gravataí", value: "CT Gravataí" },
        { label: "CT Guaíba", value: "CT Guaíba" },
        { label: "CT Guaporé", value: "CT Guaporé" },
        { label: "CT Igrejinha", value: "CT Igrejinha" },
        { label: "CT Marau", value: "CT Marau" },
        { label: "CT Montenegro", value: "CT Montenegro" },
        { label: "CT Panambi", value: "CT Panambi" },
        { label: "CT Parobé", value: "CT Parobé" },
        { label: "CT Santa Cruz", value: "CT Santa Cruz" },
        { label: "CT Santa Maria", value: "CT Santa Maria" },
        { label: "CT Santa Rosa", value: "CT Santa Rosa" },
        { label: "CT São Leopoldo", value: "CT São Leopoldo" },
        { label: "CT Sapiranga", value: "CT Sapiranga" },
        { label: "CT Sapucaia", value: "CT Sapucaia" },
        { label: "CT SSCaí", value: "CT SSCaí" },
        { label: "EI Campo Bom", value: "EI Campo Bom" },
        { label: "EI Gravataí", value: "EI Gravataí" },
        { label: "EI Nova Hartz", value: "EI Nova Hartz" },
        { label: "EI Santa Cruz", value: "EI Santa Cruz" },
        { label: "EI Sapiranga", value: "EI Sapiranga" },
        { label: "EJA Bento Gonçalves", value: "EJA Bento Gonçalves" },
        { label: "EJA Cachoeira", value: "EJA Cachoeira" },
        { label: "EJA Caxias", value: "EJA Caxias" },
        { label: "EJA Erechim", value: "EJA Erechim" },
        { label: "EJA Farroupilha", value: "EJA Farroupilha" },
        { label: "EJA Guaporé", value: "EJA Guaporé" },
        { label: "EJA Igrejinha", value: "EJA Igrejinha" },
        { label: "EJA Lajeado", value: "EJA Lajeado" },
        { label: "EJA Marau", value: "EJA Marau" },
        { label: "EJA Montenegro", value: "EJA Montenegro" },
        { label: "EJA Nova Prata", value: "EJA Nova Prata" },
        { label: "EJA Novo Hamburgo", value: "EJA Novo Hamburgo" },
        { label: "EJA Panambi", value: "EJA Panambi" },
        { label: "EJA Parobé", value: "EJA Parobé" },
        { label: "EJA Passo Fundo", value: "EJA Passo Fundo" },
        { label: "EJA Pelotas", value: "EJA Pelotas" },
        { label: "EJA Porto Alegre", value: "EJA Porto Alegre" },
        { label: "EJA Rio Grande", value: "EJA Rio Grande" },
        { label: "EJA São Leopoldo", value: "EJA São Leopoldo" },
        { label: "EJA Venâncio", value: "EJA Venâncio" },
        { label: "EM Gravataí", value: "EM Gravataí" },
        { label: "EM Gravataí", value: "EM Gravataí" },
        { label: "EM Montenegro", value: "EM Montenegro" },
        { label: "EM Pelotas", value: "EM Pelotas" },
        { label: "EM São Leopoldo", value: "EM São Leopoldo" },
        { label: "EM Sapucaia", value: "EM Sapucaia" },
      ])



    }

  }

  useEffect(() => {

    loadEscolaOptions(escolaCategory)

  }, [escolaCategory])


  const loadHours = async () => {
    addLoading({ title: 'Validando horários', key: 'horarios' })
    const activities = await api.get('/list-activities-visitation/evento');
    setActivityHours(activities?.data?.rows);
    removeLoading('horarios')
  }

  useEffect(() => {
    loadHours()
  }, [])

  const [info, setInfo] = useState({
    nome: '',
    fantasia: '',
    uf_empresa: '',
    municipio_empresa: ''
  })

  const verifyCNPJ = async (cnpj) => {

    const check = cnpj.toString().replace(/[^0-9]/g, '');

    if (check?.length < 14) {
      return;
    }

    try {
      const checkCNPJ = await api.post('/check-cnpj', { cnpj: cnpj });

      setInfo({
        nome: checkCNPJ?.data?.nome,
        fantasia: checkCNPJ?.data?.fantasia,
        uf_empresa: checkCNPJ?.data?.uf_empresa,
        municipio_empresa: checkCNPJ?.data?.municipio_empresa

      })
    }
    catch (err) {

    }


  }



  const callCityApi = async (value) => {

    const response = await Axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`);

    if (response) {

      const values: Array<IOption> = [];

      response.data.map(city => {
        values.push({ value: city.nome, label: city.nome });
      })

      setCities(values);
    }


  }

  useEffect(() => {
    callCityApi(stateValue);
  }, [stateValue])



  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
      formRef?.current?.setFieldValue('state', cepResponse.uf);
    }
  };


  const handleNewLoginUpdate = async (data: IRecoverFormData) => {
    setLoading(true);
    try {
      formRef.current?.setErrors({});

      const fieldsValues: Record<string, any> = {};
      let fieldsText = '';
      const shape = {

      };

      if (product?.fields && product?.fields.length > 0) {


        const fieldsKeys: Array<string> = [];


        let x = 1;
        product?.fields.map((f: Record<string, any>) => {
          if (f.mandatory === 'yes') {
            shape[f.field] = Yup.string().required(`Campo ${translate(f.label)} é obrigatório`);
          }
          fieldsKeys.push(f.field);
          fieldsValues[f.field] = data?.[f.field];

          if (x > 1) {
            fieldsText = `${fieldsText} | `;
          }

          fieldsText = `${fieldsText}${data?.[f.field]}`;


          x++;

        })


      }


      const schema = Yup.object().shape(shape);



      await schema.validate(data, { abortEarly: false });

      const content: Record<string, any> = {
        ...data,
        email: emailRecord,
        project_id: projectHash,
        event_id: eventHash ? eventHash : '',
        product: product._id.toString(),
        fields: { ...fieldsValues },
        fields_text: fieldsText,
      };



      const newUser = await api
        .post('/signup-update', content)
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao cadastrar',
          });
          setLoading(false);
          return false;
        });

      if (!newUser) {
        setLoading(false);
        return false;
      }

      if (window?.fbq) {
        window.fbq('track', 'CompleteRegistration');
      }

      setSuccess(true);
      setLoading(false);


      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }


  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);

    const fieldsValues: Record<string, any> = {};
    let fieldsText = '';



    let razao_social = '';
    let fantasia = '';
    let uf_empresa = '';
    let municipio_empresa = '';



    if (data?.selected_hour?.length < 1) {
      addModal({
        title: '', content: <><h2>Informe ao menos um horário de visitação</h2>
        </>, theme: 'whiteModalMini'
      })
      setLoading(false)
      return;

    }



    if (data?.company_document_number) {

      try {
        const checkCNPJ = await api.post('/check-cnpj', { cnpj: data?.company_document_number });
        razao_social = checkCNPJ?.data?.nome;
        fantasia = checkCNPJ?.data?.fantasia;
        uf_empresa = checkCNPJ?.data?.uf_empresa;
        municipio_empresa = checkCNPJ?.data?.municipio_empresa;
      }
      catch (err) {

        addModal({
          title: '', content: <><h2>Informe um CNPJ válido</h2>
          </>, theme: 'whiteModalMini'
        })
        setLoading(false)
        return;
      }


    }


    if (countryISO === 'BR') {
      data.document_type = 'cpf';
    }

    if (countryISO === 'BR') {
      const validCPF = testCPF(data?.document_number);

      if (!validCPF) {
        addToast({ type: 'error', title: 'Preencha um CPF válido' })
        setLoading(false);
        return;
      }
    }

    if (data?.extra_4) {

      data.extra_4 = data.extra_4?.[0];

    }

    if (data?.extra_5) {

      data.extra_5 = data.extra_5?.[0];

    }



    if (data?.extra_1 && typeof data?.extra_1 === 'object' && data?.extra_1?.length > 0) {

      data.extra_1 = data.extra_1.join(',');

    }


    try {
      formRef.current?.setErrors({});


      const shape: Record<string, any> = {
        name: Yup.string().required('Nome obrigatório'),
        email: Yup.string()
          .required('Email obrigatório')
          .email('Preencha um email válido'),

        phone: Yup.string()
          .required('Telefone com DDD é obrigatório'),

        position: Yup.string().required('O Profissão é obrigatório'),
        company: Yup.string().required('Campo Instituição é obrigatório'),
        document_number: Yup.string().required('Campo CPF é obrigatório'),

        state: Yup.string().required('Campo Estado é obrigatório'),
        city: Yup.string().required('Campo Município é obrigatório'),

        extra_4: Yup.string().required('Informe se deseja receber e-mails do SESI ComCiência'),
        extra_5: Yup.string().required('Informe se deseja receber e-mails do SESI'),
      };


      if (data.position !== 'Outros') {

        shape.category_id = Yup.string().required('Informe a Rede de ensino');
        shape.extra_1 = Yup.string().required('Informe o Nível ou Modalidade de Ensino');

      }



      if (product?.fields && product?.fields.length > 0) {


        const fieldsKeys: Array<string> = [];


        let x = 1;
        product?.fields.map((f: Record<string, any>) => {
          if (f.mandatory === 'yes') {
            shape[f.field] = Yup.string().required(`Campo ${translate(f.label)} é obrigatório`);
          }
          fieldsKeys.push(f.field);
          fieldsValues[f.field] = data?.[f.field];

          if (x > 1) {
            fieldsText = `${fieldsText} | `;
          }

          fieldsText = `${fieldsText}${data?.[f.field]}`;

          x++;

        })


      }


      const schema = Yup.object().shape(shape);
      data.phone = data?.phone?.toString()?.replace(/[^0-9]/g, '');












      data.email = data?.email?.trim()?.toLowerCase() || '';

      await schema.validate(data, { abortEarly: false });




      const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;

      const content: Record<string, any> = {
        ...data,
        project_id: projectHash,
        event_id: eventHash ? eventHash : '',
        captcha: captchaValue,
        term: ['Aceito'],
        fields: { ...fieldsValues },
        fields_text: fieldsText,


      };


      content.product = '66d0c5bc64b23ed96bfa231f';





      const newUser = await api
        .post('/signup', content)
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao cadastrar',
          });



          setLoading(false);
          return false;
        });

      if (!newUser) {
        setLoading(false);
        return false;
      }
      await signInDocumentNumber({ email: data?.document_number, project_id: projectHash });

      await api.post(`/apply-activities-hours/${projectHash}`, { selected_hour: data?.selected_hour });



      if (window?.fbq) {
        window.fbq('track', 'CompleteRegistration');
      }

      await signInDocumentNumber({ email: data?.document_number, project_id: projectHash })

      setSuccess(true);
      setLoading(false);
      // window.location.href = `/app/${projectHash}/user-dashboard`;


      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }

  const handleSendConfirmation = async () => {

    try {
      const confirm = await api.post(`/confirm-subscriber`, { email: currentEmail, project_id: projectHash, event_id: eventHash ? eventHash : '', product: productId ? productId : '' });

      if (confirm) {
        setSuccess(true);
        setStatusConfirmation('finished');

      }
      setLoading(false);
    }
    catch (err) {
      addToast({ type: 'error', title: 'Erro ao confirmar inscrição' });
      setLoading(false);
    }



  }

  const handleConfirmation = async (data) => {

    data.email = data?.email?.trim()?.toLowerCase() || '';

    try {
      const checkUser = await api.post(`/check-subscriber`, { email: data.email, project_id: projectHash, event_id: eventHash || '', product: productId ? productId : '' });

      if (checkUser) {
        setCurrentEmail(data.email);
        setStatusConfirmation(checkUser?.data?.status || 'new-subscriber');
        if (checkUser?.data?.product?._id) {

          setProduct(checkUser?.data?.product);
        }
        setEmailRecord(data.email)
        setReady(true);

      }

    }
    catch (err) {
      addToast({ type: 'error', title: err?.response?.data?.message || 'Erro ao checar usuário, por favor, tente novamente' });
      return
    }


  }





  if (statusConfirmation === 'finished') {

    return (
      <>

        <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center' }}>Inscrições encerradas</h1>

        <p style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center' }}>Agradecemos seu interesse, mas as vagas estão encerradas</p>

        <Link to={`/`}><button className="defaultButton" type="button">
          Voltar
        </button></Link>
      </>
    )
  }

  if (statusConfirmation === 'confirmated') {

    return (
      <>

        <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px' }}>Olá participante!</h1>

        <p style={{ color: 'rgb(0,15,64)', marginBottom: '20px' }}>Identificamos o seu cadastro em nossa plataforma. A sua inscrição no próximo evento está confirmada.</p>

        <Link to={`/app/${projectHash}/login`}><button className="defaultButton" type="button">
          Acessar
        </button></Link>
      </>
    )
  }

  if (window?.location?.pathname === '//') {
    return <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <button className="blueButtonBig" type="submit">
        INSCRIÇÕES SUSPENSAS


      </button>
    </aside>
  }



  if (statusConfirmation === 'update') {
    return <>
      {loading === true && (
        <Loader message="Preparando seu cadastro..." position="fixed" />
      )}

      <aside>
        {success ? (
          <ResponseMessage
            active
            type="clean"
            title=""
            description={
              <p style={{ color: 'rgb(0, 14, 64)', textAlign: 'center' }}>
                <h2 className="titleH2" style={{ color: 'rgb(0, 14, 64)' }}> Inscrição no evento realizada com sucesso!</h2>

                Aproveite e se inscreva em nossas palestras, oficinas ou agende visitações em grupo no botão abaixo!

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Link to={`/app/${projectHash}/user-dashboard`} >
                    <button className="defaultButton" type="button">
                      Acessar
                    </button>
                  </Link>
                </div>
                <br />

              </p>
            }
          />
        ) : (<>

          <Form ref={formRef} style={{ padding: '15px', minWidth: '320px' }} onSubmit={handleNewLoginUpdate} initialData={{}}>
            <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px' }}>Completar inscrição</h1>

            {product && product?.fields && product?.fields.length > 0 ? <CompleteSaleFields fields={product.fields} /> : <></>}

            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button className="blueButtonBig" type="submit">
                QUERO ME INSCREVER


              </button>
            </aside>
          </Form>
        </>
        )}
      </aside>
    </>
  }

  if (success) {

    return <div style={{ display: 'flex', minHeight: '50vh', padding: '20px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

      <h2 className="titleH2  purple" style={{ padding: '15px', fontSize: '24px', textAlign: 'center' }} > Sua inscrição está confirmada!</h2>
      <br />
      <p className='yellow' style={{ padding: '15px', fontSize: '18px', lineHeight: '24px' }}>
        Com sua vaga garantida, agora você pode selecionar suas atividades.
      </p>
      <p className='yellow' style={{ padding: '15px', fontSize: '18px', lineHeight: '24px' }}>
        Reserve sua vaga nas <strong className='purple'>palestras e oficinas</strong> clicando no botão abaixo.
      </p>
      <p className='yellow' style={{ padding: '15px', fontSize: '18px', lineHeight: '24px' }}>
        Se você é <strong className='purple'>educador</strong>, <strong className='purple'>agende uma visita</strong> com seus alunos também no botão abaixo. na aba de agendamentos.
      </p>

      <br />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Link to={`/app/${projectHash}/user-dashboard`} >
          <button className="defaultButtonReverse" type="button">
            Palestras, oficinas e agendamentos
          </button>
        </Link>
      </div>
      <br />


    </div>


  }


  return (
    <>
      {loading === true && (
        <Loader message="Preparando seu cadastro..." position="fixed" />
      )}

      <aside style={{ background: '#fff', padding: '50px 20px', borderRadius: '10px' }}>
        {success ? (
          <div style={{ display: 'flex', background: 'none', padding: '20px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <p style={{ color: 'rgb(0, 14, 64)' }}>
              <h2 className="titleH2  deepPurple" style={{ padding: '15px' }} > Sua inscrição está confirmada!</h2>
              <br />

              Aproveite e reserve sua vaga nas palestras e oficinas, ou para uma visita com seus alunos, clique no botão abaixo:

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Link to={`/app/${projectHash}/user-dashboard`} >
                  <button className="defaultButtonReverse" type="button">
                    Acessar
                  </button>
                </Link>
              </div>
              <br />

            </p>
          </div>


        ) : (<>

          <Form name={projectHash} ref={formRef} onSubmit={handleNewLogin} initialData={{ country: 'BR' }}>


            <Input name="name" placeholder="Nome completo" />
            <Input name="email" placeholder="E-mail" />
            {countryISO === 'BR' ? <MaskedInput
              mask="(99) 999999999"
              name="phone"
              placeholder="Telefone com DDD"
            /> : <Input name="phone" placeholder={'Telefone com DDD'} />}

            {countryISO === 'BR' ? <MaskedInput
              mask={'999.999.999-99'}
              name="document_number"
              placeholder={'CPF'}
            /> : <Input
              name="document_number"
              placeholder={'CPF'}
            />}


            <SelectSimple
              name="position"
              label="profissão"
              setCallback={(value) => setPosition(value)}
              options={[
                { label: "Outros", value: "Outros", text: "Outros" },
                { label: "Coordenador(a) de Escola", value: "Coordenador de Escola", text: "Coordenador(a) de Escola" },
                { label: "Aluno(a)", value: "Aluno", text: "Aluno(a)" },

                { label: "Cargo administrativo escolar", value: "Cargo administrativo escolar", text: "Cargo administrativo escolar" },

                { label: "Professor(a)", value: "Professor", text: "Professor(a)" },
                { label: "Diretor(a) de ensino", value: "Professor", text: "Diretor(a) de ensino" },


              ]}
            />

            {position ? <>
              {position === 'Outros' ? <Input
                name="extra_2"
                placeholder="Cargo"

              />
                : <></>}

              {position === 'Outros' ? <></> : <SelectSimple
                name="category_id"
                label="Rede de ensino"
                setCallback={(value) => setEscolaCategory(value)}
                options={[

                  { label: "Pública", value: "Pública", text: "Pública" },
                  { label: "Privada", value: "Privada", text: "Privada" },
                  { label: "Rede SESI-RS", value: "Rede SESI-RS", text: "Rede SESI-RS" },




                ]}
              />}



              {position === 'Outros' ? <Input key={`${escolaCategory}-instituição`}
                name="company"
                placeholder="Instituição"

              /> : <SelectSimple key={`${escolaCategory}-instituição`}
                name="company"
                label="Instituição de ensino"
                options={escolaOption}
              />}




              {position !== "Outros" ? position === "Aluno" ? <SelectSimple
                name="extra_1"
                label="Nível ou Modalidade de Ensino"
                options={[
                  { label: "Educação Continuada", value: "Educação Continuada" },
                  { label: "Educação Infantil", value: "Educação Infantil" },
                  { label: "Ensino Fundamental", value: "Ensino Fundamental" },
                  { label: "Ensino Médio", value: "Ensino Médio" },
                  { label: "Ensino Superior", value: "Ensino Superior" },
                  { label: "EJA", value: "EJA" },



                ]}
              /> : <CheckboxInput
                name="extra_1"
                preText={<>Nível ou Modalidade de Ensino</>}
                options={[
                  { label: "Educação Continuada", value: "Educação Continuada", id: "Educação Continuada" },
                  { label: "Educação Infantil", value: "Educação Infantil", id: "Educação Infantil" },
                  { label: "Ensino Fundamental", value: "Ensino Fundamental", id: "Ensino Fundamental" },
                  { label: "Ensino Médio", value: "Ensino Médio", id: "Ensino Médio" },
                  { label: "Ensino Superior", value: "Ensino Superior", id: "Ensino Superior" },
                  { label: "EJA", value: "EJA", id: "EJA" },



                ]}
              /> : <></>}

            </> : <></>}


            {/*   <SelectSimple setCallback={(value) => setCountryISO(value)} options={countries} name="country" label={'Pais'} /> */}

            {countryISO === 'BR' ? <SelectSimple setCallback={(value) => {
              setStateValue(value);
            }} options={states} name="state" label="Estado da Instituição/Organização" /> :

              <Input name="state" placeholder={'Província / Região'} />}


            <SelectSimple options={cities} name="city" label="Município da Instituição/Organização" />

            <label className='color2' style={{ padding: '10px 0px', display: 'block' }}>
              Você precisa de alguma necessidade específica ou recurso especial? Se sim, qual?
            </label>
            <TextareaInput name="disability_type" placeholder={''} />


            {product && product?.fields && product?.fields.length > 0 ? <aside style={{ display: 'flex', width: '100%', justifyContent: 'center', alignContent: 'center', flexDirection: 'column', margin: '30px 0px', border: '2px 0px #ddd' }}> <h2 style={{ fontSize: '12px', color: '#333', margin: '10px 0px' }}>Dados específicos do evento</h2>  <CompleteSaleFields fields={product.fields || []} /></aside> : <> </>}



            <CheckboxInputGroup

              name="selected_hour"
              preText={<>Selecione seu horário de chegada ao evento - 1 por dia</>}
              options={hours}
            />

            { /*  <label className='color2' style={{ padding: '10px 0px', display: 'block' }}>
              Se você pretende levar alunos com você, informe a quantidade abaixo:
            </label>
            <SelectSimple options={getQuantity()} name={'extra_3'} label="" /> 
          */}


            <CheckboxInput unique
              name="extra_4"
              preText={<>Aceito receber e-mails relacionados ao SESI ComCiênci@</>}
              options={[
                { label: "Sim", value: "Sim", id: "Sim" },
                { label: "Não", value: "Não", id: "Não" },




              ]}
            />

            <CheckboxInput unique
              name="extra_5"
              preText={<>Aceito receber informações sobre o SESI Educação.</>}
              options={[
                { label: "Sim", value: "Sim", id: "Sim" },
                { label: "Não", value: "Não", id: "Não" },




              ]}
            />

            <div style={{ marginBottom: '10px', padding: '15px', borderRadius: '10px' }}>


              <p
                style={{
                  padding: '10px',
                  background: 'none',
                  margin: '5px auto',
                  color: '#333',
                  fontSize: '12px',
                  textAlign: 'left',
                }}
              >

                Fico ciente de que, ao realizar a inscrição no SESI ComCiência, estarei autorizando ao SESI a utilizar meus dados pessoais para o cumprimento da finalidade da coleta, ou seja, viabilizar a participação no evento, conforme sua Política de Privacidade, disponível neste site, bem como para gestão de relacionamento, marketing e divulgação do SESI ComCiência.
                <br /><br />
                Autorizo ainda o uso da minha imagem, captada durante a realização do evento, exclusivamente para a divulgação da repercussão o SESI ComCiência.  <br /><br />
                <br /><br />


                {'Ao me cadastrar, afirmo que li a'}
                {' '}
                <strong>
                  <a
                    style={{ display: 'inline', color: '#333' }}
                    href="https://www.sesirs.org.br/politica-de-privacidade-sesi-rs"
                    target="_BLANK"
                  >
                    {'Política de Privacidade'}
                    {' '}
                  </a>
                  {' '}
                </strong>
                {'desta plataforma.'}
              </p>
            </div>
            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />

            </aside>  <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button className="blueButtonBig" type="submit">
                FINALIZAR INSCRIÇÃO


              </button>
            </aside>
          </Form>
        </>
        )}
      </aside>
    </>
  );
};

export default FormContainer;
