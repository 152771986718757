
import React, { useEffect, useState } from 'react';
import { FaCalendar, FaCalendarCheck, FaCalendarDay, FaMapMarker } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import IframePlayer from '../../../../components/IframePlayer';
import { scrollTo } from '../../../../components/Menu';
import { urlWebsite } from '../../../../config';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useLanguage } from '../../../../hooks/Language';
import {

    Capa, Container,



} from '../Themes/SinglePageTheme/styles';

const HomeApplication = () => {
    const { configuration, projectHash } = useConfiguration();
    const { translate } = useLanguage();

    const [theme, setTheme] = useState({
        titleColor: configuration?.current_event_id_response?.theme?.title_color || '#fff',
        textColor: configuration?.current_event_id_response?.theme?.text_color || '#fff',
        buttonColor: configuration?.current_event_id_response?.theme?.button_color || '#333',
        buttonBackground: configuration?.current_event_id_response?.theme?.button_background || '#3FB8EB',
    })


    useEffect(() => {
        setTheme({
            titleColor: configuration?.current_event_id_response?.theme?.title_color || '#fff',
            textColor: configuration?.current_event_id_response?.theme?.text_color || '#fff',
            buttonColor: configuration?.current_event_id_response?.theme?.button_color || '#333',
            buttonBackground: configuration?.current_event_id_response?.theme?.button_background || '#3FB8EB',
        })
    }, [configuration])


    return <>
        <div style={{ display: 'flex', width: '100%', background: '#3a2054', justifyContent: 'center', padding: '50px 20px' }}>
            <img src='/apoio/logo-branco.png' style={{ width: '250px' }} />

        </div>
        <Container className='capa'>

            <img src='/apoio/esquerda.png' className='max-500' style={{ width: '100%', maxWidth: '600px', marginBottom: '30px' }} />

            <Capa  >
                <img src='/apoio/texto.png' style={{ width: '100%', maxWidth: '500px' }} />

                <a className='full-width' href="/app/evento/inscricao">  <button className='greenButtonBig bold yellow full-width' style={{ maxWidth: '300px' }}>

                    <strong> INSCREVA-SE</strong>
                </button>
                </a>
                <Link className='full-width' to="/app/evento/login">  <button className='greenButtonBig  bold purple  full-width' style={{ maxWidth: '300px' }}>

                    <strong>EDITAR MINHA INSCRIÇÃO</strong>
                </button>
                </Link>





            </Capa>


        </Container >

    </>
}

export default HomeApplication;