import { Form } from '@unform/web';
import { addMinutes, isAfter, isBefore, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import SelectSimple from '../../../../../../components/Forms/SelectSimple';
import { useLoading } from '../../../../../../hooks/LoadingHook';
import { useModal } from '../../../../../../hooks/Modal';
import api from '../../../../../../services/api';
import { date, dateLanguageDate } from '../../../../../../utils/date';

interface IOptions {
    text?: string;
    label?: string;
    value: string;
}


interface IProps {
    projectHash: string;
    setCallback: Function;
    changeTo?: Function;

}

const Agendar: React.FC<IProps> = ({ changeTo = () => { }, projectHash, setCallback }) => {
    const { addLoading, removeLoading } = useLoading();
    const { addModal, removeModal } = useModal();
    const [list, setList] = useState<Record<string, any>[]>([]);
    const [dateList, setDateList] = useState<IOptions[]>([]);
    const [currentDate, setCurrentDate] = useState('');
    const [activities, setActivities] = useState<Record<string, any>[]>([]);
    const [myActivities, setMyActivities] = useState<Record<string, any>[]>([]);




    const hourList = currentDate ? list?.find(i => i?.hash === currentDate)?.hours?.reduce((prev: IOptions[], item: Record<string, any>) => {

        const actitity = myActivities?.find(activity => isBefore(parseISO(activity?.course_product_id_response?.date), addMinutes(parseISO(item?.date), 5)) && isAfter(parseISO(activity?.course_product_id_response?.date_end), parseISO(item?.date)))



        //  if (actitity) {
        prev.push({ label: item?.hour, value: item?.hour });
        //  }

        return prev;

    }, [] as IOptions[]) : [];

    const [currentHour, setCurrentHour] = useState('');



    const [quantityList, setQuantityList] = useState<IOptions[]>([]);


    useEffect(() => {

        if (currentHour) {


            const hour = list?.find(i => i?.hash === currentDate)?.hours?.find(a => a?.hour === currentHour);
            const available = parseInt(hour?.quantity || 0, 10) - parseInt(hour?.used || '0', 10);
            const quantityItems: IOptions[] = [];
            let x = 1;
            while (x <= available) {




                quantityItems.push({ label: x?.toString(), value: x?.toString() });
                x += 1;
            }

            setQuantityList(quantityItems)

        }


    }, [currentHour])


    const load = async () => {
        const activitiesList = await api.get(`/list-activities-visitation/${projectHash}`);
        const myactivitiesList = await api.get(`/list-my-activities/${projectHash}`);


        myactivitiesList?.data?.rows?.map((item) => {


            const activity = activitiesList?.data?.rows.find(a => a?._id?.toString() === item?.course_product_id);

            item.course_product_id_response = activity;

        })


        const response = await api.get(`/list-visitations/${projectHash}`);

        const data: IOptions[] = [];

        response?.data?.rows?.map(i => {




            data.push({ label: dateLanguageDate(i?.date), value: i?.hash?.toString() })



        })
        setActivities(activitiesList.data?.rows);
        setMyActivities(myactivitiesList?.data?.rows)
        setList(response?.data?.rows);
        setDateList(data);

    }

    useEffect(() => {



        load();


    }, [])


    const sendVisitation = async (data) => {

        if (!data?.date) {
            addModal({ title: '', theme: 'whiteModalMini', content: <p style={{ color: '#333', fontSize: '12px' }}>Informe ao menos uma data</p> })
            return
        }

        if (!data?.hour) {
            addModal({ title: '', theme: 'whiteModalMini', content: <p style={{ color: '#333', fontSize: '12px' }}>Informe ao menos um horário</p> })
            return
        }
        if (!data?.quantity) {
            addModal({ title: '', theme: 'whiteModalMini', content: <p style={{ color: '#333', fontSize: '12px' }}>Informe o número de estudantes</p> })
            return
        }


        addLoading({ title: 'Realizando agendamento', key: 'loading' });
        try {
            await api.post(`/confirm-visitation/${data?.date}`, { hour: data?.hour, quantity: data?.quantity })

            removeModal('agendar');
            setCallback();
            removeLoading('loading');
        }
        catch (err) {
            addModal({ title: '', theme: 'whiteModalMini', content: <p style={{ color: '#333', fontSize: '12px' }}>{err?.response?.data?.message || err?.response?.data?.error || 'Erro ao realizar agendamento'}</p> })
            removeLoading('loading');
        }

    }

    return (
        <>
            <Form onSubmit={sendVisitation}>

                <SelectSimple options={dateList} setCallback={(value) => setCurrentDate(value)} name="date" label="Data" />
                {currentDate ? <>

                    {hourList?.length > 0 ? <>
                        <SelectSimple options={hourList} setCallback={(value) => setCurrentHour(value)} name="hour" label="Horários disponíveis" />
                    </> : <div className='column-start pad'><p style={{ color: '#333', fontSize: '14px', textAlign: 'center', fontWeight: 'bold', marginBottom: '5px' }}>Verifique os seus horários de visitação</p>

                        <p style={{ color: '#333', fontSize: '14px', textAlign: 'center' }}>Não identificamos nenhum horário disponível em seus horários de visitação.</p>


                        <h2 style={{ margin: '10px 0px', fontSize: '14px', color: '#333', textAlign: 'center', }}>Seus horários de visitação:</h2>
                        {myActivities?.map(item => {
                            return <p className='deepPurple' style={{ padding: '5px', textAlign: 'center', marginBottom: '5px', fontSize: '14px' }}>{date(item?.course_product_id_response?.date)} - {item?.course_product_id_response?.title}</p>

                        })}

                        <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <button onClick={() => changeTo('courses')} className='buttonBigGreen yellow'  >Voltar para horários de visitação</button>
                        </aside>

                    </div>}

                    {hourList?.length > 0 ? <>
                        <SelectSimple options={quantityList} name="quantity" label="Nº de alunos" />
                        <aside style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                            <button className='defaultButtonReverse'>Agendar</button>
                        </aside> </> : <></>}
                </> : <></>}
            </Form>

        </>
    );
};
export default Agendar;
