import React, { useCallback, useRef, useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';
import { Link, useParams } from 'react-router-dom';
import { FiChevronRight, FiChevronLeft, FiX } from 'react-icons/fi';
import api from '../../services/api';
import { date, hour, dateLanguage, simpleDate, dateWithHour } from '../../utils/date';
import { Container } from './styles';
import { addHours, addMinutes, endOfDay, isAfter, isBefore, parseISO, startOfDay } from 'date-fns';
import { useToast } from '../../hooks/Toast';
import { Form } from '@unform/web';

import { string } from 'yup';

import { useLanguage } from '../../hooks/Language';
import { FaArrowDown, FaArrowUp, FaCheck, FaExclamation, FaPlus } from 'react-icons/fa';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import Favorite from '../../components/ClassRoomContainer/modules/Favorite';
import { useAuth } from '../../hooks/Auth';
import { useConfiguration } from '../../hooks/Configuration';
import { hash } from '../../config';
import { loadFromJson } from '../../utils/loadFromJson';
import { useModal } from '../../hooks/Modal';
import { useLoading } from '../../hooks/LoadingHook';
import SelectSimple from '../Forms/SelectSimple';
import { usePrinter } from '../../hooks/PrinterHook';

interface IOption {
  value: string;
  label: string;
}

interface IProgram {
  _id: string;
  date: string;
  formatted_date?: string;
  start_hour: Date;
  date_end: string;
  formatted_start_hour?: string;
  end_date: string;
  formatted_end_date?: string;
  title: string;
  category: string;
  room_reference_id_response: Record<string, any>;
  description: string;
  visible?: string;
  course_product_id?: string;
  quantity: number;
}

interface IProgramFilter {
  date: Date;
  formatted_date: string;
  program: Array<IProgram>;
  simpleDate: string;
  day_reference?: string;
  category?: string;
  room_name?: string;
}

interface IProps {
  filterCategory?: string;
  removeCategory?: string;
  changeTo?: Function;
}


const CourseProductsSubscriptionModule: React.FC<IProps> = ({ filterCategory = '', removeCategory = '', changeTo = () => { } }) => {
  const [globalActivitiesList, setGlobalActivitiesList] = useState<Array<Record<string, any>>>([]);


  const { addLoading, removeLoading } = useLoading();
  const [currentDay, setCurrentDay] = useState(0);
  const { addModal, removeModal } = useModal();
  const [search, setSearch] = useState('');
  const [currentProgram, setCurrentProgram] = useState('');
  const [roomList, setRoomList] = useState<Array<IOption>>([]);
  const { addToast } = useToast();
  const { user, handleApiErrors } = useAuth();
  const { translate } = useLanguage();
  const { configuration } = useConfiguration();
  const { projectHash, eventHash } = useParams<Record<string, any>>();


  const [filterContent, setFilterContent] = useState('Horário de chegada');
  const [filterList, setFilterList] = useState<IOption[]>([]);
  const [myActivitiesList, setMyActivitiesList] = useState<IProgram[]>([]);
  const [myVisitations, setMyVisitations] = useState<IProgram[]>([]);
  const { addPrint, printIt, clearPrint } = usePrinter();


  const printContent = (dates, filter) => {

    addLoading({ title: 'Preparando impressão', key: 'print' })
    const contentPrint: JSX.Element[] = [];

    dates.map(program => {

      const filtered = filter ? filter === 'myActivities' ? program?.activities?.filter(i => i?.currentStatus === 'selected') : program?.activities?.filter(i => i?.category === filter) : program?.activities;



      if (filtered?.length > 0) {
        filtered?.map(item => {

          contentPrint.push(<tr><td>{item?.date_text}</td><td>{item?.category}</td><td>{item?.title}</td><td>{item?.students || '0'}</td></tr>)


        })


      }
    })




    addPrint([<div className='column ' style={{ width: '100%', display: 'flex', padding: '30px', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column' }}><table className='table'>
      <img src="/assets/logo2.png" style={{ maxWidth: '300px', width: '100%', margin: '30px 0px' }} />
      <tbody>
        <tr><td style={{ fontWeight: 'bold' }}>Data</td><td style={{ fontWeight: 'bold' }}>Categoria</td><td style={{ fontWeight: 'bold' }}>Atividade</td><td style={{ fontWeight: 'bold' }}>Acompanhantes</td></tr>
        {contentPrint}
      </tbody>
    </table></div>]);

    setTimeout(() => {
      removeLoading('print')
      printIt();

    }, 1000)


    setTimeout(() => clearPrint(), 3000)


  }


  const load = async () => {

    addLoading({ title: 'Carregando', key: 'loading' });

    try {

      const listVisitations = await api.get(`/list-my-visitations/${projectHash}`);
      const listActivities = await api.get(`/list-activities/${projectHash}`);
      const listMyActivities = await api.get(`/list-my-activities/${projectHash}`);

      const data = listActivities.data.rows;

      const activitiesList: Array<Record<string, any>> = [];
      const myActivitiesList: Array<Record<string, any>> = [];

      const categories: IOption[] = [];

      listActivities?.data?.rows?.map(i => {

        const indexCat = categories?.findIndex(a => a?.value === i?.category);

        if (indexCat < 0) {
          categories.push({ label: i?.category, value: i?.category });
        }

      })

      listMyActivities?.data?.rows?.map(myActivity => {
        const activity = listActivities?.data?.rows.find(i => i?._id === myActivity.course_product_id);

        myActivity.category = activity.category;
        myActivity.date = activity.date;
        myActivity.date_end = activity.date_end;

      });

      listMyActivities?.data?.rows?.map(myActivity => {

        const index = listActivities?.data?.rows?.findIndex(activity => activity?._id?.toString() === myActivity?._id?.toString());




        if (index < 0) {
          myActivitiesList.push(myActivity)
        }
        else {
          myActivitiesList.push(listActivities?.data?.rows[index])
        }

      })


      if (data) {
        data.map((elem) => {
          const textDate = simpleDate(elem.date);

          const index = activitiesList?.findIndex(activityDates => activityDates?.simpleDate === textDate);

          /* check if the activity is already selected */
          const indexActivity = myActivitiesList?.findIndex(activity => activity?.course_product_id?.toString() === elem?._id?.toString());


          let alreadyHaveGroup = false;

          /* have any activity from the same group, but not the same activity */

          elem?.group?.map(groupValue => {


            const indexActivityGroup = myActivitiesList?.findIndex(activityItems => activityItems?.group?.findIndex(g => g === groupValue) >= 0);

            if (indexActivityGroup >= 0) {
              alreadyHaveGroup = true;
            }

          })


          if (indexActivity >= 0) {
            elem.currentStatus = 'selected';

            if (myActivitiesList?.[indexActivity]?.students > 0) {
              elem.students = myActivitiesList?.[indexActivity]?.students?.toString();
            }

          }
          else if (alreadyHaveGroup) {
            elem.currentStatus = 'blocked';
          }
          else if (elem?.status === 'no') {
            elem.currentStatus = 'ended';
          }
          else if (elem?.quantity <= elem?.quantity_used) {
            elem.currentStatus = 'full';
          }
          else {
            elem.currentStatus = 'available';
          }

          elem.date_text = dateWithHour(elem.date);


          if (index < 0) {
            activitiesList.push({
              date: elem.date,
              formatted_date: dateLanguage(elem.date),
              simpleDate: textDate,
              activities: [elem]
            })
          }
          else {
            activitiesList[index].activities.push(elem)
          }



        });


        activitiesList.map((item, index) => {


          activitiesList[index].activities.sort((a, b) => {
            return a.date > b.date ? 1 : a.date < b.date ? -1 : 0;
          });
        });

        activitiesList.sort((a, b) => {
          return a.date > b.date ? 1 : a.date < b.date ? -1 : 0;

        });
        categories.sort((a, b) => {
          return a.value > b.value ? 1 : a.value < b.value ? -1 : 0;

        });

        setMyVisitations(listVisitations?.data?.rows)

        setMyActivitiesList(listMyActivities?.data?.rows)
        setFilterList(categories);
        setGlobalActivitiesList(activitiesList);
        removeLoading('loading');
      }
    }
    catch (err) {
      removeLoading('loading');
    }
  };

  useEffect(() => {


    load();
  }, [projectHash, user]);

  const confirmActivity = async (data, id) => {
    addLoading({ title: 'Carregando', key: 'loading' });
    try {

      const confirmActivityResponse = await api.post(`/confirm-activity-participation/${id}`, { students: data?.students ? parseInt(data?.students, 10) : 0 });

      await load();
      removeModal('confirmDecline');
      removeLoading('loading');
      addToast({ title: 'Adicionado com sucesso', type: 'success' });
    }
    catch (err) {
      removeLoading('loading');
      return addToast({ title: err.response.data.message || err.response.data.error || 'Erro ao realizar solicitação' })
    }

  }

  const declineActivity = async (id) => {
    addLoading({ title: 'Carregando', key: 'loading' });
    try {

      const confirmActivityResponse = await api.put(`/decline-activity-participation/${id}`);

      await load();
      removeModal('confirmDecline');
      removeLoading('loading');
      addToast({ title: 'Desistência realizada com sucesso', type: 'success' });
    }
    catch (err) {
      removeLoading('loading');
      return addToast({ title: err.response.data.message || err.response.data.error || 'Erro ao realizar solicitação' })
    }

  }

  const askModal = (activityInfo, type) => {

    let value = activityInfo?.quantity - activityInfo?.quantity_used;
    let options: IOption[] = [];

    let x = 0;
    while (x < value) {
      options.push({ label: x?.toString(), value: x?.toString() })

      x += 1;
    }


    if (type === 'confirm') {


      let allow = true;

      if (activityInfo?.allow_students === 'yes') {


        const checkVisitation = myVisitations.find(i => isBefore(startOfDay(parseISO(i?.date)), addMinutes(parseISO(activityInfo?.date), 5)) && isAfter(endOfDay(parseISO(i?.date)), parseISO(activityInfo?.date)));

        if (!checkVisitation) {
          allow = false;
        }
        else {
          options = [];
          let x = 1;
          while (x <= checkVisitation?.quantity) {
            options.push({ label: x?.toString(), value: x?.toString() })

            x += 1;
          }


        }
      }












      addModal({
        theme: 'whiteModalMedium',
        key: 'confirmDecline', title: '', content: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px', flexDirection: 'column' }}>
          <h2 style={{ color: '#333', fontSize: '18px' }}>Confirmar participação nesta atividade?</h2>
          <p style={{ color: '#333', fontSize: '16px' }}>{activityInfo?.title}</p>

          <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <button onClick={() => confirmActivity({ students: 0 }, activityInfo?._id?.toString())} className='buttonBigGreen purple'  >Confirmar inscrição individual</button>
          </aside>

          <Form initialData={{ students: '0' }} onSubmit={(data) => confirmActivity(data, activityInfo?._id?.toString())}>
            {activityInfo?.allow_students === 'yes' ? allow ? <>
              <p style={{ fontSize: '14px', padding: '15px', margin: '10px 0px' }} className='deepPurple'>
                Caso seja um educador, você pode reservar vagas para os seus alunos no campo abaixo.
              </p>
              <SelectSimple options={options} name="students" label='Digite o número de alunos que o acompanharão' />

              <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <button className='buttonBigGreen purple'  >Confirmar inscrição de grupo</button>
              </aside>
            </> : <>

              <h2 className='deepPurple' style={{ padding: '15px', textAlign: 'center' }}>Caso queira agendar a participação de seus alunos nesta atividade, realize o agendamento de visita de grupos para este dia.<br />
              </h2>

              <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <button onClick={() => { changeTo('agendar'); removeModal('confirmDecline') }} type="button" className='buttonBigGreen yellow'  >Agendar visita de grupos</button>
              </aside>
            </>
              : <></>}

          </Form>
        </div>
      })
    }
    else if (type === 'decline') {
      addModal({
        theme: 'whiteModalMedium',
        key: 'confirmDecline', title: '', content: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px', flexDirection: 'column' }}>
          <h2 style={{ color: '#333', fontSize: '18px' }}>Você deseja desistir desta atividade?</h2>
          <p style={{ color: '#333', fontSize: '16px' }}>{activityInfo?.title}</p>
          <button className='buttonBigGreen' style={{ background: 'rgb(150,0,0)' }} onClick={() => declineActivity(activityInfo?._id?.toString())}>Desistir</button>
        </div>
      })
    }
    else if (type === 'blocked') {
      addModal({
        theme: 'whiteModalMedium',
        key: 'confirmDecline', title: '', content: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px', flexDirection: 'column' }}>
          <h2 style={{ color: '#333', fontSize: '18px' }}>Atividade Bloqueada</h2>
          <p style={{ color: '#333', fontSize: '16px' }}>Você só pode se inscrever em uma atividade por horário / data</p>
        </div>
      })
    }
    else if (type === 'ended') {
      addModal({
        theme: 'whiteModalMedium',
        key: 'confirmDecline', title: '', content: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px', flexDirection: 'column' }}>
          <h2 style={{ color: '#333', fontSize: '18px' }}>Vagas esgotadas</h2>
          <p style={{ color: '#333', fontSize: '16px' }}>Selecione outra atividade</p>
        </div>
      })
    }
    else if (type === 'out') {
      addModal({
        theme: 'whiteModalMedium',
        key: 'confirmDecline', title: '', content: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px', flexDirection: 'column' }}>
          <h2 style={{ color: '#333', fontSize: '18px' }}>Atividade fora do horário de visitação selecionado</h2>
          <p style={{ color: '#333', fontSize: '16px' }}>Acesse a aba "Horários de Visitação"</p>
        </div>
      })
    }
  }

  const checkStatus = (line) => {

    const validHour = myActivitiesList.filter(i => {




      return i?.category === 'Horários de visitação' && isBefore(parseISO(i?.date), addMinutes(parseISO(line?.date), 5)) && isAfter(parseISO(i?.date_end), parseISO(line?.date));
    }).length

    //const valid = line?.category === 'Horários de visitação' ? true : validHour >= 1 ? true : false
    const valid = true;
    if (line?.currentStatus === 'selected') {
      return <button className='buttonBigGreenIcon  hover hoverToCancel' onClick={() => { askModal(line, 'decline') }} style={{ background: 'rgb(0,150,50)' }} title="Selecionado"><FaCheck className='show' /> <FiX title='Cancelar' className='showOnHover' />  </button>;
    }
    else if (line?.currentStatus === 'blocked') {
      return <button className='buttonBigGreenIcon' onClick={() => { askModal(line, 'blocked') }} style={{ background: 'rgb(150,150,150)' }} title="Bloqueado"><FaExclamation /> </button>;

    }
    else if (line?.currentStatus === 'ended') {
      return <button className='buttonBigGreenIcon' onClick={() => { askModal(line, 'ended') }} style={{ background: 'rgb(150,0,0)' }} title="Encerrado"><FiX /> </button>;

    }
    else if (line?.currentStatus === 'full') {
      return <button className='buttonBigGreenIcon' onClick={() => { askModal(line, 'ended') }} style={{ background: 'rgb(150,0,0)' }} title="Esgotado"> <FiX /></button>;

    }

    else if (!valid) {
      return <button className='buttonBigGreenIcon' onClick={() => { askModal(line, 'out') }} style={{ background: 'orange' }} title="Oficina fora do horário de visitação solicitado"> <FiX /></button>;

    }
    else {
      return <button className='buttonBigGreenIcon hover hoverToCheck deepPurple' onClick={() => { askModal(line, 'confirm') }} style={{ background: 'rgb(0,50,150)' }} title="Adicionar"><FaPlus className='show' /> <FaCheck className='showOnHover' /> </button>;

    }




  }

  const line = program => {
    return (
      <tr style={{ cursor: 'pointer' }} onClick={() => setCurrentProgram(state => state === program.id ? '' : program.id)}>
        <td className="hourDiv" style={{ width: '60px', maxWidth: '60px', minWidth: '60px' }} >
          {checkStatus(program)}

        </td>  <td style={{ width: '100%' }}>
          {/*  {program?.category ? <label className='deepPurple' style={{ fontSize: '10px', width: 'auto', padding: '3px 5px', marginBottom: '5px', color: '#fff', maxWidth: 'auto', display: 'inline-block' }}>{program?.category}</label> : <></>} */}

          <span>
            <strong className='colorPurple'>
              {' '}

              {program.title}
              {' '}


            </strong>

            {program.description && <div className={"hover"}>
              <div style={{ width: '100%' }}>


                <div className="miniPrograma" dangerouslySetInnerHTML={{ __html: program.description }} />

              </div>


            </div>}

            {program.currentStatus === 'selected' ? <p className='purple' style={{ width: 'auto', padding: '5px', display: 'inline-block' }}>{program.students ? `Sua inscrição e de seus ${program.students} alunos estão confirmadas` : `Inscrição confirmada`}</p> : <></>}

          </span>
        </td>

      </tr>
    );
  };

  return (
    <Container style={{ margin: '0px' }}>
      <h1 style={{ color: '#fff', fontSize: '18px', textAlign: 'center' }}>{translate('Selecione as atividades que deseja participar')} </h1>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'wrap', gap: '10px', marginBottom: '10px' }}>
        {filterList?.map(i => <button style={{ width: 'auto', minWidth: 'auto' }} className={filterContent === i?.value ? 'defaultButton' : 'defaultButtonReverse'} onClick={() => {
          if (i?.value === filterContent) { setFilterContent('') }
          else { setFilterContent(i?.value) }
        }}>{i?.value}</button>)}
        <div className='row'>
          <button style={{ width: 'auto', minWidth: 'auto' }} className={filterContent === 'myActivities' ? 'row defaultButton gap-sm' : 'row defaultButtonReverse gap-sm'} onClick={() => { setFilterContent('myActivities') }}>Minhas atividades          <div style={{ background: '#fff', color: 'rgb(58, 29, 85)', fontWeight: 'bold', width: '25px', height: '25px', borderRadius: '50%', fontSize: '14px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {myActivitiesList?.length}
          </div></button>

        </div>
      </div>

      {filterContent === 'Palestras' ? <p className='yellow' style={{ maxWidth: '800px', fontSize: '16px', padding: '10px' }}>
        Selecionamos especialistas de diversas áreas do conhecimento para discutir questões
        conectadas com os princípios da educação. Para garantir a sua participação,
        selecione as palestras que você irá participar
      </p> : <></>}

      {filterContent === 'Oficinas para alunos' ? <p className='yellow' style={{ maxWidth: '800px', fontSize: '16px', padding: '10px' }}>
        As oficinas abaixo necessitam de inscrição prévia para participação. Para um
        melhor aproveitamento das oficinas, é necessário que você realize o tour no
        espaço do SESI Lab antes da oficina. Para garantir a sua participação ou de seus
        alunos, selecione as oficinas que você irá
        participar. Demais oficinas para alunos não
        necessitam inscrição prévia e serão por
        ordem de chegada.
      </p> : <></>}

      {filterContent === 'Horário de chegada' ? <p className='yellow' style={{ maxWidth: '800px', fontSize: '16px', padding: '10px' }}>
        Selecione o seu horário de chegada no evento
      </p> : <></>}

      {filterContent === 'myActivities' ? myActivitiesList?.length > 0 ? <div className='purple'><p className='purple' style={{ maxWidth: '800px', fontSize: '16px', padding: '10px' }}>
        <strong className="purple">Você está confirmado(a)</strong> nas atividades abaixo!<br />
        Caso deseje, clique no botão abaixo para imprimir sua grade de palestras e oficinas.

      </p><button className='yellow' style={{ margin: '0px 10px', marginBottom: '15px' }} onClick={() => printContent(globalActivitiesList, filterContent)}>Imprimir</button> </div> : <div className='purple'><p className='yellow' style={{ maxWidth: '600px', fontSize: '16px', padding: '10px' }}>
        Você ainda não selecionou nenhuma oficina ou palestra para participar

      </p>  </div> : <></>}

      {filterContent === 'Oficinas Professores' ? <p className='yellow' style={{ maxWidth: '600px', fontSize: '16px', padding: '10px' }}>
        As oficinas abaixo necessitam de inscrição prévia para participação.
      </p> : <></>}
      {globalActivitiesList.map(program => {

        const filtered = filterContent ? filterContent === 'myActivities' ? program?.activities?.filter(i => i?.currentStatus === 'selected') : program?.activities?.filter(i => i?.category === filterContent) : program?.activities;


        return (filtered?.length > 0 ? <div style={{ width: '100%' }}>
          <div className="programDayHeader" >


            {program.formatted_date && <h2 className='purple' style={{ marginTop: '30px', padding: '10px', marginLeft: '7px' }}>{translate(program.formatted_date)}</h2>}

          </div>
          <table className='full-width' style={{ width: '100%' }}>

            <tbody>{program.activities.map(items => items?.visible === 'yes' && (filterContent === '' || filterContent === items?.category || (filterContent === 'myActivities' && items?.currentStatus === 'selected')) ? line(items) : <></>)}</tbody>
          </table>

        </div> : <></>)
      })}

    </Container>
  );
};
export default CourseProductsSubscriptionModule;
