import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaBell, FaCertificate, FaCheckCircle, FaExclamationCircle, FaFile, FaIdBadge, FaImages, FaQuestionCircle, FaReceipt, FaRegIdBadge, FaSubscript, FaTicketAlt, FaTrophy, FaUser } from 'react-icons/fa';
import { FiMessageCircle } from 'react-icons/fi';

import { IconType } from 'react-icons/lib';
import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';
import api from '../../../../services/api';
import InOut from '../../../DashboardPages/core/components/Forms/InOut';


import PagamentosGeral from '../PagamentosGeral';


import { Container, ContainerWhite } from './styles';
import { useParams } from 'react-router-dom';
import { useModal } from '../../../../hooks/Modal';
import { useToast } from '../../../../hooks/Toast';
import Input from '../../../../components/Forms/Input';
import Loader from '../../../../components/Loader';
import SelectSimple from '../../../../components/Forms/SelectSimple';
import CheckboxInput from '../../../../components/Forms/CheckboxInput';
import { useLanguage } from '../../../../hooks/Language';
import CodeUsage from '../InscricoesComMeuCodigo';
import CourseProductsSubscriptionModule from '../../../../components/CourseProductSubscription';
import Agendamentos from '../Agendamentos';




const UserHome: React.FC = () => {

  const { user } = useAuth();
  const { projectHash } = useParams<Record<string, any>>();
  const [userInfo, setUserInfo] = useState<Record<string, any>>({});
  const [projects, setProjects] = useState<Array<Record<string, any>>>([]);
  const [dashboardStatus, setDashboardStatus] = useState('courses');
  const { addModal } = useModal();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);


  const changeStatus = (value) => {
    setDashboardStatus(value);
    window.scrollTo(0, 0);
  }

  const { configuration } = useConfiguration();
  const [myMedicSubscription, setMyMedicSubscription] = useState<Record<string, any>>({})
  const { translate } = useLanguage();
  const loadProjects = async () => {
    const response = await api.get('/get-projects');
    if (response?.data) {

      response?.data?.rows.sort((a, b) => {
        return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
      })


      setProjects(response?.data.rows);
    }
  }



  useEffect(() => {
    loadProjects();

  }, [])



  const dashboardStates = [
    { title: translate('Palestras e oficinas'), Icon: <FaBell />, state: 'courses', getPage: () => <PagamentosGeral changeStatus={(value) => changeStatus(value)} />, show: false },

    //{ title: translate('Meus Pagamentos'), Icon: <FaBell />, state: 'myPayments', getPage: () => <PagamentosGeral changeStatus={(value) => changeStatus(value)} />, show: false },
    // { title: translate('Códigos utilizados'), Icon: <FaReceipt />, state: 'myCodeUsage', getPage: () => <CodeUsage changeStatus={(value) => changeStatus(value)} />, show: false },
  ]







  const currentState = dashboardStates.find(state => state.state === dashboardStatus);





  return (
    <>
      {loading && <Loader message='Carregando ...' />}
      <Container id="dashboardUser" >
        <div className='p800 headerBoard' >
          <img src='/assets/logo.png' style={{ width: '200px' }} />
          <div style={{ display: 'flex', width: '100%', gap: '10px', marginBottom: '15px', justifyContent: 'flex-end' }}>

            <button className={dashboardStatus === 'courses' ? 'defaultButtonMini yellow' : 'defaultButtonMini purple'} onClick={() => setDashboardStatus('courses')} style={{ fontWeight: 'bold' }}>{translate('Palestras e Oficinas')}</button>
            <button className={dashboardStatus === 'agendar' ? 'defaultButtonMini  yellow' : 'defaultButtonMini purple'} onClick={() => setDashboardStatus('agendar')} style={{ fontWeight: 'bold' }}>{translate('Agendar visita de grupos')}</button>
          </div>


          {dashboardStatus === 'courses' ? <CourseProductsSubscriptionModule changeTo={(step) => setDashboardStatus(step)} /> : <></>}
          {dashboardStatus === 'agendar' ? <Agendamentos changeTo={(step) => setDashboardStatus(step)} /> : <></>}
        </div>
      </Container>
    </>
  );
};
export default UserHome;
