import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaBell, FaCertificate, FaFile, FaIdBadge, FaImages, FaQuestionCircle, FaReceipt, FaRegIdBadge, FaSubscript, FaTicketAlt, FaTrophy, FaUser } from 'react-icons/fa';
import { FiMessageCircle } from 'react-icons/fi';

import { IconType } from 'react-icons/lib';
import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useLanguage } from '../../../../hooks/Language';
import { useLoading } from '../../../../hooks/LoadingHook';
import { useModal } from '../../../../hooks/Modal';
import api from '../../../../services/api';
import { dateFullWithHour, dateLanguageDate } from '../../../../utils/date';
import format from '../../../../utils/format';
import InOut from '../../../DashboardPages/core/components/Forms/InOut';
import Sale from '../../../DashboardPages/pages/Sales';
import Agendar from './modules/Agendar';

import { Container, ContainerWhite } from './styles';

interface IProps {
  changeTo: Function;

}

const Agendamentos: React.FC<IProps> = ({ changeTo = () => { } }) => {

  const { user } = useAuth();

  const [tickets, setTickets] = useState<Array<Record<string, any>>>([])
  const { addModal, removeModal } = useModal();
  const { projectHash, configuration } = useConfiguration();
  const { translate } = useLanguage();
  const { addLoading, removeLoading } = useLoading();

  const load = async () => {



    const response = await api.get(`/list-my-visitations/${projectHash}`);


    setTickets(response?.data?.rows || []);


  }

  useEffect(() => {



    load();


  }, [])

  const agendar = async (changeTo) => {

    addModal({ title: '', theme: 'whiteModalMini', content: <Agendar changeTo={changeTo} projectHash={projectHash} setCallback={() => load()} />, key: "agendar" })

  }

  const confirmDeclineVisitation = async (visitation) => {



    addLoading({ title: 'Cancelando agendamento', key: 'loading' });
    try {
      await api.put(`/decline-visitation/${visitation?._id?.toString()}/${visitation?.hour}`)

      removeModal('agendar');
      load();
      removeLoading('loading');
    }
    catch (err) {
      addModal({ title: '', theme: 'whiteModalMini', content: <p style={{ color: '#333', fontSize: '12px' }}>{err?.response?.data?.message || err?.response?.data?.error || 'Erro ao realizar agendamento'}</p> })
      removeLoading('loading');
    }


  }

  const cancelVisitation = async (visitation) => {

    addModal({ title: '', theme: 'whiteModalMini', content: <><h2 style={{ color: '#333', width: '100%', textAlign: 'center' }}>Você deseja confirmar o cancelamento da visita?</h2><button onClick={() => confirmDeclineVisitation(visitation)} className='defaultButtonMini deepPurple' style={{ padding: '10px', borderRadius: '10px', minWidth: '150px', margin: '10px auto' }} type='button'>Sim</button></>, key: "agendar" })

  }


  return (
    <>
      <Container >

        <div className='user-block  ' style={{ overflow: 'hidden' }}>
          <div className='content-block' >


            <div className=' column' style={{ gap: '10px', marginBottom: '15px', padding: '0px', width: '100%', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
              <h1 style={{ color: '#fff', fontSize: '18px', textAlign: 'center' }}>{translate('Meus agendamentos')} </h1>

              <p className='yellow' style={{ maxWidth: '600px', padding: '10px', fontSize: '16px' }}>
                Vai com a sua turma visitar o SESI ComCiênci@? Agende sua visita! Selecione dia,
                horário e quantas pessoas irão!<br /><br />

                *Importante: Somente o titular da inscrição receberá o certificado de participação
              </p>

              <button onClick={() => agendar(changeTo)} className='defaultButtonMini purple'>Clique para agendar visita</button>
            </div>

            <table><tbody>
              {tickets?.length > 0 ? <tr><th></th><th className='purple'>{translate('Data')}</th><th className='purple'>{translate('Horário')}</th> <th className='purple'>{translate('Nº de alunos')}</th> </tr> : <></>}
              {tickets.map(sale => {

                return <tr key={sale._id}>
                  <td style={{ fontSize: '14px', lineHeight: '16px' }}>
                    <button onClick={() => { cancelVisitation(sale) }} style={{ padding: '15px' }} className='defaultButtonMini deepPurple' type='button'>Cancelar</button></td>
                  <td style={{ fontSize: '14px', lineHeight: '16px' }}>{dateLanguageDate(sale?.date)}</td>
                  <td style={{ fontSize: '14px', lineHeight: '16px' }}>
                    {sale?.hour} </td>
                  <td style={{ fontSize: '14px', lineHeight: '16px' }}>
                    <strong>{sale?.quantity?.toString()}</strong></td>

                </tr>
              })}


              {tickets?.length < 1 &&
                <tr  ><td colSpan={3}>{translate('Nenhuma inscrição identificada com um código da sua conta')}</td></tr>}
            </tbody>
            </table>
          </div>

        </div>
      </Container>
    </>
  );
};
export default Agendamentos;
